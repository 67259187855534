<template>
  <!-- 客户列表 -->
  <div
   v-loading.fullscreen.lock="loading"
   element-loading-text="请求数据中"
   element-loading-background="rgba(255, 255, 255, 0.5)"
  >
   <div class="append">
     <div class="seach">
       <el-select 
        clearable 
        placeholder="选择国家" 
        v-model="pageInfo.country"
       >
        <el-option
          v-for="item in countryList"
          :label="item.name"
          :key="item.id"
          :value="item.id"
        ></el-option>
       </el-select>
       <el-select 
        placeholder="请选择店铺" 
        v-model="pageInfo.profileId"
       >
        <el-option-group 
          :key="group.id"
          :label="group.name"
          v-for="group in shopList"
        >
          <el-option 
            v-for="item in group.marketPlaceTree"
            :key="item.profileId"
            :label="group.name+'-'+item.name"
            :value="item.profileId"
            @click.native="checkGroup(group.id, item.id)"
           >{{ item.name }}
          </el-option>
        </el-option-group>
       </el-select>
       <el-select clearable placeholder="选择时间" v-model="pageInfo.buyTime">
         <el-option label="最近购买时间" value="1"></el-option>
         <el-option label="最早购买时间" value="0"></el-option>
       </el-select>
       <TimeQuantum
         style="margin-right:10px" 
         @selectFinish='topTime'
         section="近7天"
         @sectionFinish='sectionSure'
       ></TimeQuantum>
       <el-input clearable placeholder="请输入邮箱" v-model="pageInfo.email"></el-input>
       <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
       <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
       <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin-right:20px;float:right"></tableDialog>
       <el-table
        border
        style="width: 100%"
        :data="tableList"
        :selectable="selectable"
        @sort-change="sortTable"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
       >
        <template v-for="item in tableHead">
          <el-table-column
            :key="item.prop"
            :prop="item.prop"
            :label="item.name"
            :sortable="item.sortable"
            header-align="center"
            align="center"
            :width="item.width"
          >
          </el-table-column>
        </template>
       </el-table>
       <!-- 分页区域 -->
       <el-pagination
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         layout="total, sizes, prev, pager, next, jumper"
         :page-sizes="[20, 30, 50, 100]"
         :total="total"
         :page-size="pageInfo.pageSize"
         :current-page="pageInfo.current">
       </el-pagination>
     </div>
   </div>
  </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum'
import tableDialog from '@/components/tableDialog.vue'
import {
  pageInitList,  // 查询初始化
  listPage,  // 查询分页
}from '@/api/Mail/customerList.js'
export default {
   components: {
     tableDialog,
     TimeQuantum,
   },
   data() {
     return {
       total: 0,
       loading: false,
       pageInfo: {
         country: "",  
         profileId: "", // 店铺Id
         buyTime: "", // 购买时间
         email: "",   // 邮箱
         current: 1,
         pageSize: 20,  // 每页20条
         startDate: "",
         endDate: "",
         sortType: "",
         sort: "",
       },
       shopId: "",
       marketplaceId: "",
       tableList: [],
       tableHead: [],
       tableData: [
         { name: "店铺", prop: "shopName", sortable: false},
         { name: "国家", prop: "", sortable: false},
         { name: "邮箱", prop: "mailAddress", sortable: false},
         { name: "订单总数", prop: "orderCounts", sortable: true},
         { name: "退款订单数", prop: "backorderCounts", sortable: true},
         { name: "最早购买时间", prop: "earlyBuyTime", sortable: true},
         { name: "最近购买时间", prop: "lastBuyTime", sortable: true},
         { name: "买家国家", prop: "buyerCountry", sortable: false},
       ],
       shopList: [],
       countryList: [],
     }
   },
   created() {
     this.tableHead = this.tableData;
     this.getPageInit();
   },
   mounted() {
     setTimeout(() => {
       this.search();
     }, 500);
   },
   methods: {
     setTableHead(list) {
       this.tableHead = list;
     },
     getPageInit() {
       pageInitList()
       .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.shopList = res.data.data.shopList;
          res.data.data.shopList.forEach(item => {
            item.marketPlaceTree.forEach(data=> {
              if(data.isSelected == 1) {
                this.shopId = item.id;
                this.marketplaceId = data.id;
                this.pageInfo.profileId = data.profileId;
              }
            })
          });
          this.countryList = res.data.data.countryList;
        }
       })
     },
     checkGroup(parentId, subId) {
       this.shopId = parentId;
       this.marketplaceId = subId;
     },
     topTime(val) {
       this.pageInfo.startDate = val.value[0];
       this.pageInfo.endDate = val.value[1];
     },
     reset() {
       this.pageInfo.country = "";
       this.pageInfo.profileId = "";
       this.pageInfo.buyTime = "";
       this.pageInfo.email = "";
     },
     search() {
       this.loading = true;
       let params = {
         keyword: this.pageInfo.email,  // 邮箱查询
         shopId: this.shopId,
         marketplaceId: this.marketplaceId,
         dateType: this.pageInfo.buyTime,
         startDate: this.pageInfo.startDate,
         endDate: this.pageInfo.endDate,
         current: this.pageInfo.current,
         pageSize: this.pageInfo.pageSize,
         sortType: this.pageInfo.sortType,
         sort: this.pageInfo.sort
       }
       listPage(params)
        .then(res=> {
          this.loading = false;
          this.tableList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.tableList = res.data.values;
            this.total = res.data.pageInfo.total;
          }
       })
     },
     sortTable(column) {
       console.log(column);
       if(column.order == 'ascending') {
         this.pageInfo.sortType = 0;
       }else if(column.order == 'descending') {
         this.pageInfo.sortType = 1;
       }else if(column.order == null) {
         this.pageInfo.sort = '';
         this.pageInfo.sortType = '';
         this.search();
         return false;
       }
       if(column.prop == "orderCounts") {
         this.pageInfo.sort = "order_counts";
       }else if(column.prop == "backorderCounts") {
         this.pageInfo.sort = "back_counts";
       }else if(column.prop == "earlyBuyTime") {
         this.pageInfo.sort = "early_buy_time";
       }else if(column.prop == "lastBuyTime") {
         this.pageInfo.sort = "last_buy_time	";
       }
       this.search();
     },
     handleSizeChange(newSize) {
       this.pageInfo.pageSize = newSize;
       this.search();
     },
     handleCurrentChange(newCurrent) {
       this.pageInfo.current = newCurrent;
       this.search();
     }
   }
}
</script>

<style lang="scss" scoped>
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-input {
        width: 10%;
        margin-right: 15px;
      }
      .el-table {
        margin-top: 25px;
        margin-bottom: 50px;
      }
      .el-select {  
        margin-right: 20px;
      }
      .el-pagination {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
</style>